import React from 'react';
import { NoDataTable, PreLoaders } from '../../helpers';
import { DeleteIcon, DetailsIcon, DownloadIcon, ReloadIcon } from '../../helpers/icons/Icons';
import { Navigate } from 'react-router-dom';

const RestaurantSettingsTable = ({ loading, tblHeader, bodyKeys, bodyData, handleDelete, showDetails, setShowModal, isDataLinked = false, linkedUrl = null, isShowDelete = true, isShowActions = true }) => {
    const handleLocation = (e, id) => {
        const isCheckbox = e.target.type === 'checkbox';

        if (isCheckbox) {
            // Prevent navigation if the clicked element is the checkbox
            e.preventDefault();
            return;
        }
        Navigate(linkedUrl + id)
    }
    return (
        <div className="table-wrapper">
            <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-[#f8fafc]'>
                    <tr>
                        {tblHeader.map((header, index) => (
                            <th scope="col" className="table-head-style" key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                {
                    loading ? <PreLoaders /> : ""
                }
                <tbody className="divide-y divide-gray-200">
                    {bodyData?.length > 0 && bodyData.map((row, rowIndex) => (
                        <tr key={rowIndex} onClick={isDataLinked ? (e) => handleLocation(e, row?.id) : null}>
                            {
                                bodyKeys.map((keys, index) => <td className="table-body-style" key={index}>{row[keys]}</td>)
                            }
                            {
                                isShowActions && <td className="table-body-style">
                                    {showDetails && showDetails[0] && <button type="button" className="text-primary-300 hover:text-primary-400  font-normal px-2 py-1 rounded-[4px] text-[13px]" onClick={() => { showDetails[1](row); setShowModal(true) }}>
                                        <DetailsIcon />
                                    </button>}
                                    {isShowDelete && <button type="button" className="text-green-500 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px] text-[13px] mr-4" >
                                        <DownloadIcon />
                                    </button>}
                                    {isShowDelete && <button type="button" className="text-yellow-400 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px] text-[13px]" >
                                        <ReloadIcon />
                                    </button>}
                                </td>
                            }

                        </tr>
                    ))}
                </tbody>
            </table>
            {bodyData?.length === 0 && <NoDataTable />}
            {/* Delete Confirmation Modal */}
            {/* {isDeleteConfirmationModalOpen && (
                <div className={`modal-wrapper  ${isDeleteConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                    <p className="mb-6">Want to delete this staff!</p>
                                    <div className="flex justify-end">
                                        <button
                                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                            onClick={() => setIsDeleteConfirmationModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={handleDeleteStaff}
                                        >
                                            Yes, delete it!
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </turbo-frame>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default RestaurantSettingsTable;