import React, { useState } from 'react';
import { DokaaniTable, ModalWithTable } from '../../hooks';
import RestaurantSettingsTable from './RestaurantSettingsTable';

const RestaurantSettingTab = () => {
    const [restaurantData, setRestaurantData] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);

    console.log(restaurantData)
    console.log(isSubmit)
    const formData = {
        fields: [
            {
                name: "name",
                type: "text",
                label: "Stuff Name",
                required: true,
                placeholder: "Rahim Hossain",
                value: "",
            },
            {
                name: "mobile",
                type: "phone",
                label: "Stuff Mobile",
                required: true,
                placeholder: "01*********",
                value: "",
            },
            {
                name: "role",
                type: "text",
                label: "Stuff Role",
                placeholder: "Manager",
                required: true,
                value: "",
            },
            {
                name: "status",
                type: "stuffStatus",
                label: "Stuff Status",
                required: true,
                value: "Active",
            },
            {
                name: "address",
                type: "textarea",
                label: "Stuff Address",
                placeholder: "Full Address",
                required: true,
                value: "",
            }
        ]
    }


    const formData2 = [
        {
            "id": "12",
            "assignedStuff": "Shakib",
        },
        {
            "id": "2",
            "assignedStuff": "Ahad Hossain",
        },
        {
            "id": "9",
            "assignedStuff": "Ishtiak Ahmed",
        },
        {
            "id": "6",
            "assignedStuff": "Shabbir Benn Tabib",
        },
        {
            "id": "5",
            "assignedStuff": "Zihad",
        },
        {
            "id": "8",
            "assignedStuff": "Jubair al sabid",
        }
    ]
    return (
        <div className='form-section-wrapper rounded-none border-none'>
            <ModalWithTable
                twoColumn={true}
                tableName={"All QR Codes"}
                newTableName={"Add QR Code"}
                btnLabel={"Add QR Code"}
                formData={formData}
                setFormData={setRestaurantData}
                isSubmit={setIsSubmit}
            >
                {/* <DokaaniTable tblHeader={["ID", "Name", "Mobile", "Role", "Status", "Address", "Action"]} bodyKeys={["id", "stuff_name", "stuff_mobile", "stuff_role", "status", "address"]} bodyData={allStuff} handleDelete={handleDelete} /> */}
                <RestaurantSettingsTable bodyData={formData2} tblHeader={["Table Number", "Assigned Stuff", "QR Actions"]} bodyKeys={["id", "assignedStuff"]} />
            </ModalWithTable>
        </div>
    );
};

export default RestaurantSettingTab;