import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import NoDataTable from '../helpers/NoDataTable';
import PreLoaders from '../helpers/PreLoaders';
import { DeleteIcon, DetailsIcon } from '../helpers/icons/Icons';
import { parentContext } from '../App';
export default function DokaaniTable({ loading, tblHeader, bodyKeys, bodyData, handleDelete, showDetails, setShowModal, isDataLinked = false, linkedUrl = null, isShowDelete = true, isShowActions = true }) {

    const navigate = useNavigate();

    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [deleteStaffId, setDeleteStaffId] = useState(null);
    const { setModalIndex } = useContext(parentContext);


    const handleLocation = (e, id) => {
        const isCheckbox = e.target.type === 'checkbox';

        if (isCheckbox) {
            // Prevent navigation if the clicked element is the checkbox
            e.preventDefault();
            return;
        }
        navigate(linkedUrl + id)
    }

    // const handleDeleteConfirmation = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You want to delete this item!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleDelete(id);
    //         }
    //     });
    // };

    const handleStaffDeleteConfirmation = (id) => {
        setIsDeleteConfirmationModalOpen(true);
        setDeleteStaffId(id);
    };

    const handleDeleteStaff = () => {
        if (deleteStaffId) {
            handleDelete(deleteStaffId);
            setIsDeleteConfirmationModalOpen(false);
        }
    };

    // isDeleteConfirmationModalOpen for stop the sidebar 
    useEffect(() => {
        setModalIndex(isDeleteConfirmationModalOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteConfirmationModalOpen])

    return (
        <div className="table-wrapper">
            <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-[#f8fafc]'>
                    <tr>
                        {tblHeader.map((header, index) => (
                            <th scope="col" className="table-head-style" key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                {
                    loading ? <PreLoaders /> : ""
                }
                <tbody className="divide-y divide-gray-200">
                    {bodyData?.length > 0 && bodyData.map((row, rowIndex) => (
                        <tr key={rowIndex} onClick={isDataLinked ? (e) => handleLocation(e, row?.id) : null}>
                            {
                                bodyKeys.map((keys, index) => <td className="table-body-style" key={index}>{row[keys]}</td>)
                            }
                            {
                                isShowActions && <td className="table-body-style">
                                    {showDetails && showDetails[0] && <button type="button" className="text-primary-300 hover:text-primary-400  font-normal px-2 py-1 rounded-[4px] text-[13px]" onClick={() => { showDetails[1](row); setShowModal(true) }}>
                                        <DetailsIcon />
                                    </button>}
                                    {isShowDelete && <button type="button" className="text-red-500 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px] text-[13px]" onClick={() => handleStaffDeleteConfirmation(row?.id)}>
                                        <DeleteIcon />
                                    </button>}
                                </td>
                            }

                        </tr>
                    ))}
                </tbody>
            </table>
            {bodyData.length === 0 && <NoDataTable />}
            {/* Delete Confirmation Modal */}
            {isDeleteConfirmationModalOpen && (
                <div className={`modal-wrapper  ${isDeleteConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                    <p className="mb-6">Want to delete this staff!</p>
                                    <div className="flex justify-end">
                                        <button
                                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                            onClick={() => setIsDeleteConfirmationModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={handleDeleteStaff}
                                        >
                                            Yes, delete it!
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </turbo-frame>
                    </div>
                </div>
            )}
        </div>
    )
}
