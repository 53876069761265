import axios from "axios";
import { GetCookie } from "./Cookies";

export default function useAxios() {

	let secure_auth = atob(GetCookie("ask")) || "",
		baseURL = "https://api.bonik.io",
		axiosObject = null,
		thisEnvironment = 'prod';

	if (window.location.href.includes("stage")) {
		baseURL = "https://stageapi.bonik.io";
		thisEnvironment = "stage";
	} else if (window.location.href.includes("localhost")) {
		baseURL = "http://dokaani.local";
		thisEnvironment = "local";
	}

	baseURL += "/query/v1";

	axiosObject = axios.create({
		baseURL,
		headers: {
			Authorization: `Bearer ${secure_auth}`,
		},
	});

	axiosObject.environment = thisEnvironment;

	return axiosObject;
}
